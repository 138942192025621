@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Rubik', sans-serif;
}

* {
    line-height: 1.5;
    border-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* rc-components */

.rc-dropdown {
    position: absolute;
    left: -9999px;
    top: -9999px;
    z-index: 1070;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
}
.rc-dropdown-hidden {
    display: none;
}
.rc-dropdown-menu {
    outline: none;
    position: relative;
    list-style-type: none;
    /*padding: 0;*/
    /*margin: 2px 0 2px;*/
    /*text-align: left;*/
    /*background-color: #fff;*/
    /*border-radius: 3px;*/
    /*box-shadow: 0 1px 5px #ccc;*/
    background-clip: padding-box;
    /*border: 1px solid #ccc;*/
}

.rc-dropdown-menu:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    /*background: #ffffff;*/
    /*background: rgba(255, 255, 255, 0.01);*/
}
.rc-dropdown-menu > .rc-dropdown-menu-item {
    position: relative;
    display: block;
    /*padding: 7px 10px;*/
    clear: both;
    /*font-size: 12px;*/
    font-weight: normal;
    /*color: #666666;*/
    white-space: nowrap;
}
.rc-dropdown-menu > .rc-dropdown-menu-item:hover,
.rc-dropdown-menu > .rc-dropdown-menu-item-active,

.rc-dropdown-menu > .rc-dropdown-menu-item-selected {
    position: relative;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-disabled {
    /*color: #ccc;*/
    cursor: not-allowed;
    pointer-events: none;
}
.rc-dropdown-menu > .rc-dropdown-menu-item-disabled:hover {
    /*color: #ccc;*/
    /*background-color: #fff;*/
    cursor: not-allowed;
}

.rc-dropdown-menu > .rc-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    /*background-color: #e5e5e5;*/
    line-height: 0;
}
.rc-dropdown-slide-up-enter,
.rc-dropdown-slide-up-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    display: block !important;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
}
.rc-dropdown-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    display: block !important;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
    animation-name: rcDropdownSlideUpIn;
    animation-play-state: running;
}
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
    animation-name: rcDropdownSlideDownIn;
    animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
    animation-name: rcDropdownSlideUpOut;
    animation-play-state: running;
}
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
    animation-name: rcDropdownSlideDownOut;
    animation-play-state: running;
}
@keyframes rcDropdownSlideUpIn {
    0% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
}
@keyframes rcDropdownSlideUpOut {
    0% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
    }
}
@keyframes rcDropdownSlideDownIn {
    0% {
        opacity: 0;
        transform-origin: 0% 100%;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform-origin: 0% 100%;
        transform: scaleY(1);
    }
}
@keyframes rcDropdownSlideDownOut {
    0% {
        opacity: 1;
        transform-origin: 0% 100%;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0% 100%;
        transform: scaleY(0);
    }
}

.gradient {
    background: linear-gradient(245.31deg, #F3A45B 16.02%, #BF73CB 53.11%, #EA4D56 84.52%);
}

.black-friday {
    background: url('assets/bf_bg.png') no-repeat;
    background-size: cover;
    background-position: center;
}

.animated-btn {
    background: linear-gradient(-45deg, rgb(20 21 22), #338AFF, rgb(255, 200, 21));
    background-size: 600%;
    animation: anime 16s linear infinite;
    color: white;
}

@keyframes anime {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes loader1111 {
    0% {
        transform: rotate(0deg);
    }
    2.94% {
        transform: rotate(0deg);
    }
    5.88% {
        transform: rotate(90deg);
    }
    25% {
        transform: rotate(90deg);
    }
    31.25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    56.25% {
        transform: rotate(270deg);
    }
    75% {
        transform: rotate(270deg);
    }
    81.25% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loaderYellow {
    0% {
        transform: rotate(0deg);
    }
    3.125% {
        transform: rotate(0deg);
    }
    6.25% {
        transform: rotate(-90deg);
    }
    28.125% {
        transform: rotate(-90deg);
    }
    31.25% {
        transform: rotate(-180deg);
    }
    53.125% {
        transform: rotate(-180deg);
    }
    56.25% {
        transform: rotate(-270deg);
    }
    78.125% {
        transform: rotate(-270deg);
    }
    81.25% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes loaderRed {
    0% {
        transform: rotate(0deg);
    }
    9.375% {
        transform: rotate(0deg);
    }
    12.5% {
        transform: rotate(-90deg);
    }
    34.375% {
        transform: rotate(-90deg);
    }
    37.5% {
        transform: rotate(-180deg);
    }
    59.375% {
        transform: rotate(-180deg);
    }
    62.5% {
        transform: rotate(-270deg);
    }
    84.375% {
        transform: rotate(-270deg);
    }
    87.5% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes loaderViolet {
    0% {
        transform: rotate(0deg);
    }

    15.625% {
        transform: rotate(0deg);
    }
    18.75% {
        transform: rotate(-90deg);
    }

    40.625% {
        transform: rotate(-90deg);
    }
    43.75% {
        transform: rotate(-180deg);
    }

    65.625% {
        transform: rotate(-180deg);
    }
    68.75% {
        transform: rotate(-270deg);
    }

    90.625% {
        transform: rotate(-270deg);
    }
    93.75% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes loaderBlue {
    0% {
        transform: rotate(0deg);
    }

    21.875% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-90deg);
    }

    46.875% {
        transform: rotate(-90deg);
    }
    50% {
        transform: rotate(-180deg);
    }

    71.875% {
        transform: rotate(-180deg);
    }
    75% {
        transform: rotate(-270deg);
    }

    96.875% {
        transform: rotate(-270deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}


@keyframes loaderRing {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
