.image-carousel__index {
    text-rendering: geometricPrecision;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    white-space: nowrap;
}

.image-carousel__index__current {
    display: inline-block;
    color: #fff;
    position: relative;
}

.image-carousel__index__current--visible,
.image-carousel__index__current--next {
    transition: none;
    transform-origin: center center 10px;
}

.image-carousel__index__current--visible {
    display: inline-block;
    position: relative;
    transform: rotateX(0deg);
}
.image-carousel__index__current--next {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateX(90deg);
}

.image-carousel__index__current.is-transitioning-forward
.image-carousel__index__current--visible,
.image-carousel__index__current.is-transitioning-forward
.image-carousel__index__current--next {
    transition: transform 300ms cubic-bezier(0.694, 0, 0.335, 1);
}
.image-carousel__index__current.is-transitioning-forward
.image-carousel__index__current--next {
    transform: rotateX(0deg);
}
.image-carousel__index__current.is-transitioning-forward
.image-carousel__index__current--visible {
    transform: rotateX(-90deg);
}
